import { BrowserRouter, Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Head from 'next/head'
import Image from 'next/image'
import { ROUTES_TO_BE_HIDDEN, RouteId, devRoutes } from '../constants/routes'
import Button from '../components/Button'
import { RouteComponentWrapper } from '../components/RouteComponentWrapper'
import { useWeb3 } from '../context/Web3Context'
import Navbar from '../components/Layout/navbar'

const BlockedPage = (): JSX.Element => {
  const { chainId } = useWeb3()
  const isDev =
    localStorage.getItem('isDev') !== null ? localStorage.getItem('isDev') === 'true' : false

  return isDev ? (
    <BrowserRouter>
      <Navbar>
        <div className="md:p-8 md:pt-0">
          <Routes>
            <Route path="/451" element={<RedirectDev />} />
            {Object.entries(devRoutes)
              .filter(([routeId]) => !ROUTES_TO_BE_HIDDEN[routeId as RouteId]?.includes(chainId))
              .map(([, route]) => (
                <Route
                  key={route.name}
                  path={route.path}
                  element={
                    <RouteComponentWrapper>
                      {route.component && <route.component />}
                    </RouteComponentWrapper>
                  }
                />
              ))}
            <Route path="*" element={<Navigate to={devRoutes.SWAP.path} />} />
          </Routes>
        </div>
      </Navbar>
    </BrowserRouter>
  ) : (
    <>
      <BrowserRouter>
        <Head>
          <title>Unavailable due to legal reasons</title>
        </Head>
        <div
          style={{ backgroundImage: `url(/assets/error/background.png)` }}
          className="mx-auto flex h-screen w-full flex-col items-center justify-center bg-center px-4 text-center"
        >
          <h1 className="font-Jamjuree text-3xl font-bold leading-[67px] text-wombatBrown3 md:text-4xl lg:text-6xl">
            Oops! Error 451.
          </h1>
          <h4 className="mb-10 font-Jamjuree text-[24px] font-bold leading-[32px] text-wombatBrown3 lg:mb-20">
            Wombat has gone incognito in your neck of the woods.
          </h4>
          <Image src="/assets/error/451.png" alt="451" layout="fixed" width="400" height="175" />
          <p className="mt-10 max-w-[710px] text-left font-Work font-bold leading-[28px] text-wombatBrown1 lg:mt-20 ">
            <Link
              to="/"
              className="text-wombatPurple underline underline-offset-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wombat Exchange
            </Link>{' '}
            and its related websites are not available to, are not intended for, and any
            communications from Wombat Exchange or its representatives are not intended to be acted
            upon by, any persons or entities who are located or incorporated in (i) the USA, (ii)
            the United Kingdom, (iii) Hong Kong, (iv) any country which are subject to any
            sanctions, including those programs run by OFAC and the United Nations Security Council,
            or (v) any person in a restricted country.
          </p>
        </div>
      </BrowserRouter>
    </>
  )
}

const RedirectDev = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <div className="mx-auto flex w-full max-w-[1088px] pt-20">
      <Button onClick={() => navigate(devRoutes.SWAP.path)}>Continue</Button>
    </div>
  )
}

export default BlockedPage
